import React from 'react';
import { useApiLoading } from 'react-reqq';
import FormInput from 'modules/common/forms/FormInput';
import logo from 'assets/img/logo-dark.svg';
import * as actions from '../actions';
import * as c from '../constants';

function Login() {
  const [form, setForm] = React.useState({
    email: '',
    password: '',
  });
  const isLoading = useApiLoading(c.LOGIN, 'post');
  const handleSubmit = (e) => {
    e.preventDefault();
    actions.login(form);
  };
  return (
    <div className="h-screen w-screen flex bg-primary-500">
      <div className="container m-auto">
        <div className="bg-white rounded-lg border shadow-lg m-auto py-8 px-12 w-1/3 mb-16">
          <div className="flex">
            <img className="h-24 mx-auto" src={logo} alt="brand" />
          </div>
          <div className="text-center text-sm font-light mb-3">
            National Bureau of Investigation
          </div>
          <form onSubmit={handleSubmit}>
            <div className="mb-2">
              <FormInput
                name="email"
                label="Email"
                onChange={setForm}
                value={form.email}
                type="email"
                required
              />
            </div>
            <div className="mb-2">
              <FormInput
                name="password"
                label="Password"
                onChange={setForm}
                value={form.password}
                type="password"
                required
              />
            </div>
            <button
              className="btn primary rounded-full w-full"
              type="submit"
              disabled={isLoading}
            >
              Login
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

Login.propTypes = {};

export default Login;
