import React from 'react';
import { showAlert, showModal } from 'modules/common/Modal';
import { Link, useLocation, useParams } from 'react-router-dom';
import PdfPreview from 'modules/subscribers/sub-modules/transactions/components/PdfPreview';
// import PropTypes from 'prop-types';

function Inquire() {
  const location = useLocation();
  const { refno } = useParams();
  const data = {
    refno: 'OT-ALA-00001',
    email: 'alsapalat@multisyscorp.com',
    name: 'Adam Lee Apalat',
    position: 'Front-end',
    department: 'Research & Development',
    date: '2020-10-07',
    time_from: '18:00',
    time_to: '22:00',
    reason: 'Other stuff...',
    remarks: '',
  };
  const handlePrint = () => {
    const print = () => {
      showModal({
        title: 'Preview',
        className: 'modal-xl',
        content: (onClose) => (
          <PdfPreview url="/print/refno" onClose={onClose} />
        ),
      });
    };
    if (location?.state?.is_new) {
      showAlert({
        content: 'This form is not yet fully approved, proceed anyways?',
        onYes: (closeAlert) => {
          print();
          closeAlert();
        },
        onYesLabel: 'Print',
        onNoLabel: 'Cancel',
      });
      return;
    }
    print();
  };
  return (
    <div className="h-screen w-screen flex bg-black">
      <div className="container m-auto">
        <div className="bg-white rounded-lg border shadow-lg m-auto py-12 px-6 w-3/5 mb-16">
          <div className="text-center text-lg text-gray-600 font-semibold border-b">
            Application Status
          </div>
          <div className="text-center text-4xl font-bold tracking-tighter border-b mb-3">
            {refno}
          </div>
          <div className="grid grid-cols-3 gap-1">
            <div className="text-gray-600 text-sm flex justify-end items-end">
              Submitted By:
            </div>
            <div className="text-gray-800 col-span-2 font-semibold">
              {data.name}
            </div>
            <div className="text-gray-600 text-sm flex justify-end items-end">
              Position:
            </div>
            <div className="text-gray-800 col-span-2 font-semibold">
              {data.position}
            </div>
            <div className="text-gray-600 text-sm flex justify-end items-end">
              Department:
            </div>
            <div className="text-gray-800 col-span-2 font-semibold">
              {data.department}
            </div>

            <div className="text-gray-600 text-sm flex justify-end items-end">
              OT Date:
            </div>
            <div className="text-gray-800 col-span-2 font-bold">
              {data.date}
            </div>
            <div className="text-gray-600 text-sm flex justify-end items-end">
              Effectivity:
            </div>
            <div className="text-gray-800 col-span-2 font-bold">
              {data.time_from} - {data.time_to}
            </div>
            <div className="text-gray-600 text-sm flex justify-end items-end">
              Reason:
            </div>
            <div className="text-gray-800 col-span-2 font-semibold italic">
              {data.reason}
            </div>
          </div>
          <table className="table-auto mb-4 w-full">
            <thead>
              <tr>
                <th className="px-4 pt-2">&nbsp;</th>
                <th className="px-4 pt-2 text-xs leading-none">
                  Immediate Supervisor
                </th>
                <th className="px-4 pt-2 text-xs leading-none">
                  Payroll Officer
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="text-center border px-4 py-2 text-xs">Status</td>
                <td className="text-center border px-4 py-2">
                  {!location?.state?.is_new ? (
                    <i className="fa fa-2x fa-check-circle text-green-500" />
                  ) : (
                    <i className="fa fa-2x fa-minus-circle text-yellow-500" />
                  )}
                </td>
                <td className="text-center border px-4 py-2">
                  {!location?.state?.is_new ? (
                    <i className="fa fa-2x fa-check-circle text-green-500" />
                  ) : (
                    <i className="fa fa-2x fa-minus-circle text-yellow-500" />
                  )}
                </td>
              </tr>
              <tr>
                <td className="text-center border px-4 py-2 text-xs truncate">
                  Approved By
                </td>
                <td className="text-center border px-4 py-2 text-sm">
                  Supervisor
                </td>
                <td className="text-center border px-4 py-2 text-sm">
                  Payroll
                </td>
              </tr>
              <tr>
                <td className="text-center border px-4 py-2 text-xs">
                  Approved Date
                </td>
                <td className="text-center border px-4 py-2 text-sm">
                  10/10/2020
                </td>
                <td className="text-center border px-4 py-2 text-sm">
                  10/11/2020
                </td>
              </tr>
            </tbody>
          </table>
          <div className="mb-6">
            <button
              className="btn primary rounded-full w-full"
              type="submit"
              onClick={handlePrint}
            >
              <i className="fa fa-print mr-1" />
              Print
            </button>
          </div>
          <div className="text-center">
            <Link className="font-semibold underline" to="/">
              Back to homepage.
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

Inquire.propTypes = {};

export default Inquire;
