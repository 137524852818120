import React from 'react';
import { req, useApiGet } from 'react-reqq';
import Cookie from 'js-cookie';
import * as c from './constants';

export const useAuth = () => {
  const auth = useApiGet(c.AUTHENTICATION, {});
  React.useEffect(() => {
    const token = Cookie.get('_token');
    const type = Cookie.get('_userType');
    req.set(c.AUTHENTICATION, {
      isAuthenticated: !!token,
      type,
    });
  }, []);
  return [typeof auth.isAuthenticated === 'boolean', auth.isAuthenticated];
};

export const useProfile = () => {
  const data = useApiGet(c.PROFILE_DATA, {});
  React.useEffect(() => {
    const profileData = Cookie.get('_profileData');
    const newProfileData = JSON.parse(profileData);
    req.set(c.PROFILE_DATA, newProfileData);
  }, []);
  return data;
};
