import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Inquire from 'modules/partial/containers/Inquire';
import Search from 'modules/partial/containers/Search';
import Login from 'modules/auth/containers/Login';
import InquirePrintForm from 'modules/inquire/components/InquirePrintForm';

function Public() {
  return (
    <Switch>
      <Route exact path="/login" component={Login} />
      <Route path="/print/:refno">
        <InquirePrintForm />
      </Route>
      <Route exact path="/search" component={Search} />
      <Route path="/search/:refno" component={Inquire} />
      <Route path="/logout">
        <Redirect to="/" />
      </Route>
      <Route path="/">
        <Redirect to="/login" />
      </Route>
    </Switch>
  );
}

Public.propTypes = {};

export default Public;
