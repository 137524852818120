import { req } from 'react-reqq';
import Cookie from 'js-cookie';
// import { toastError } from 'react-geek-toast';
import * as c from './constants';

export const login = (payload) => {
  req.post({
    key: c.LOGIN,
    url: '/api/v1/authentication',
    payload,
    onSuccess: (res) => {
      const data = res?.response?.data || {}
      const profile = {
        id: data?.id,
        email: data?.attributes?.email,
        email_verified_at: data?.attributes?.email_verified_at,
        is_active: data?.attributes?.is_active,
        mobile_number: data?.attributes?.mobile_number,
        mobile_verified_at: data?.attributes?.mobile_verified_at,
        profile_id: data?.attributes?.profile_id,
        profile_type: data?.attributes?.profile_type,
        _is_email_verified: data?.attributes?._is_email_verified,
        _is_mobile_verified: data?.attributes?._is_mobile_verified,
        first_name: data?.attributes?.profile?.first_name,
        last_name: data?.attributes?.profile?.last_name,
      };
      const token = res?.response?.token;
      Cookie.set('_profileData', profile);
      Cookie.set('_token', token);
      Cookie.set('_userType', 'ADMIN');
      // Cookie.set('_profileData', data, { expires: 7 });
      // Cookie.set('_token', token, { expires: 7 });
      // Cookie.set('_userType', 'ADMIN', { expires: 7 });
      req.set(c.AUTHENTICATION, {
        isAuthenticated: true,
        type: 'ADMIN',
      });
    },
  });
};

export const register = (payload, onSuccess) => {
  req.post({
    key: c.REGISTER,
    url: '/api/register',
    payload,
    onError: () => {
      onSuccess();
    },
  });
};

export const logout = () => {
  Cookie.remove('_profileData');
  Cookie.remove('_token');
  Cookie.remove('_userType');
  req.set(c.AUTHENTICATION, {
    isAuthenticated: false,
    type: null,
  });
};
