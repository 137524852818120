import React from 'react';
// import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import Barcode from 'react-barcode';
import { logPrintHistory } from 'modules/print-history/actions';

const getInit = () => {
  try {
    return JSON.parse(sessionStorage.getItem('for-print'));
  } catch (err) {
    return {};
  }
};

function InquirePrintForm() {
  const [data] = React.useState(getInit());
  return (
    <div
      className="paper-a4 flex"
      // style={{ backgroundImage: `url(${reference})` }}
    >
      <div className="fixed p-3 no-print w-full text-right">
        <button
          className="btn primary"
          type="button"
          onClick={() => {
            window.print();
            logPrintHistory(data.txnid);
          }}
        >
          <i className="fa fa-print mr-1" />
          Print
        </button>
      </div>
      <div className="mx-auto mt-24 text-lg" style={{ maxWidth: 400 }}>
        <div className="leading-none mb-2">
          <div className="uppercase">
            {[data.barangay, data.municipality, data.province, data.region]
              .filter((r) => !isEmpty(r))
              .join(', ')}
          </div>
          <div>PHILIPPINES</div>
        </div>
        <div className="leading-none mb-2">
          <div className="uppercase">{data.street1}</div>
          <div className="uppercase">{data.street2}</div>
        </div>
        {data.address_remarks && (
          <div className="leading-none my-2">
            <div className="italic">
              Remarks: <span className="uppercase">{data.address_remarks}</span>
            </div>
          </div>
        )}
        <div className="leading-none mb-2">
          <div className="font-bold">{data.full_name}</div>
          <div className="font-light">{data.contact_number}</div>
          <div className="font-light">{data.contact_number_2}</div>
        </div>
        <div className="leading-none mb-2">
          <div className="font-bold slashed-zero">
            NBI Reference No.: {data.txnid}
          </div>
        </div>
        <div className="flex justify-center">
          <Barcode width={2} height={40} value={data.reference} />
        </div>
      </div>
    </div>
  );
}

InquirePrintForm.propTypes = {};

export default InquirePrintForm;
