import React from 'react';
import FormInput from 'modules/common/forms/FormInput';
import { useHistory } from 'react-router-dom';

function Search() {
  const history = useHistory();
  const [form, setForm] = React.useState({
    refno: '',
  });
  const handleInquire = (e) => {
    e.preventDefault();
    history.push(`/search/${form.refno}`);
  };
  return (
    <div className="h-screen w-screen flex bg-black">
      <div className="container m-auto">
        <div className="bg-white rounded-lg border shadow-lg m-auto p-12 w-1/3 mb-16">
          <form onSubmit={handleInquire}>
            <div className="mb-4">
              <FormInput
                name="refno"
                onChange={setForm}
                value={form.refno}
                placeholder="Enter OT Reference Number..."
                required
              />
            </div>
            <button className="btn primary rounded-full w-full" type="submit">
              Check Status
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

Search.propTypes = {};

export default Search;
