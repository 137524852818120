import React from 'react';
import PropTypes from 'prop-types';

function PdfPreview({ url, onClose }) {
  return (
    <div>
      <div className="w-full shadow-inner-lg" style={{ height: 600 }}>
        <iframe
          frameBorder="0"
          src={`${url}#toolbar=0`}
          title="preview"
          width="100%"
          height="100%"
        />
      </div>
      <div className="p-3 flex justify-center">
        <button
          className="btn outline-primary rounded sm"
          type="button"
          onClick={onClose}
        >
          Close
        </button>
      </div>
    </div>
  );
}

PdfPreview.propTypes = {
  url: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default PdfPreview;
