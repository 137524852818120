import { toastSuccess } from 'react-geek-toast';
import { req } from 'react-reqq';
import moment from 'moment';
import * as c from './constants';

export const listPrintHistory = (params) => {
  req.get({
    key: c.PRINT_HISTORY_LIST,
    url: '/api/v1/client/megov/print-history',
    params,
    transform: (res) => {
      req.set(c.PRINT_HISTORY_PAGER, res?.meta?.pagination || {});
      return (res.data || []).map((item) => ({
        created_at: moment(new Date(item?.attributes?.created_at)).format(
          'LLLL'
        ),
        refno: item?.attributes?.txnid,
        print_count: item?.attributes?.count,
        updated_at: moment(new Date(item?.attributes?.updated_at)).format(
          'LLLL'
        ),
      }));
    },
  });
};

export const logPrintHistory = (refno, onSuccess) => {
  req.post({
    key: c.PRINT_HISTORY_LIST,
    url: `/api/v1/client/megov/transactions/${refno}/print`,
    onSuccess: () => {
      toastSuccess('Print was logged!');
      if (onSuccess) onSuccess();
    },
  });
};
